import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentLeft,
    BannerContentRight,
    BannerBtn,
    BannerPara,BannerBtnsWrapper,
    BannerH3,
    BannerH4
} from './banner.style'
import {Container} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
            hostingJson{
                Banner{
                        BannerH4
                        BannerH3
                        BannerPara
                        BannerBtn
                        BannerImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `);
    return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentLeft>
                        <Fade delay={200}>
                            <BannerH4>{JSONData.hostingJson.Banner.BannerH4}</BannerH4>
                            <BannerH3>{JSONData.hostingJson.Banner.BannerH3}</BannerH3>
                            <BannerPara>{JSONData.hostingJson.Banner.BannerPara}</BannerPara>
                        </Fade>
                        <Fade delay={400}>
                            <BannerBtnsWrapper>
                                <BannerBtn href="#">{JSONData.hostingJson.Banner.BannerBtn}</BannerBtn>
                            </BannerBtnsWrapper>
                        </Fade>
                    </BannerContentLeft>


                    <BannerContentRight>
                        <Fade delay={200}>
                            <GatsImg 
                                fluid={JSONData.hostingJson.Banner.BannerImage.childImageSharp.fluid} 
                                alt=""
                                className="BannerImage"
                                imgStyle={{
                                    width:"auto",
                                    right:"0px",
                                    margin:"0px auto"
                                }}
                            />
                        </Fade>
                    </BannerContentRight>
                    
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;