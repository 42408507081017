import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {FooterCustomContainerfluid,FooterSection,FooterCol,FooterTitle,FooterPara,
    FooterLi,FooterSocial,BottomFooterPara,InstaIcon,LinkedinIcon,TwitterIcon,FbIcon,
    BottomFooterWrapper,BottomLink} from './footer.style';
import Fade from "react-reveal/Fade";

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
            query{
                hostingJson {
                    Footer {
                        DelayConstant
                        Items
                        FooterCol1{
                            FooterTitle
                            FooterPara{
                                Para
                            }
                        }
                        FooterCol2{
                            FooterTitle
                            FooterPara{
                                Para
                            }
                        }
                        FooterCol3{
                            FooterTitle
                            FooterUl{
                                Menu
                                Href
                            }
                        }
                        FooterCol4{
                            FooterTitle
                        }
                        BottomFooterPara
                        BottomFooterName
                        BottomFooterLink
                    }
                }
            }
        `);
        return (
        <div>
            <FooterCustomContainerfluid>
                <FooterSection>
                    <Container>
                        <Fade delay={JSONData.hostingJson.Footer.DelayConstant}>
                            <Row>
                            <Col md="3">
                                    <FooterCol>
                                    <FooterTitle>{JSONData.hostingJson.Footer.FooterCol1.FooterTitle}</FooterTitle>
                                    {
                                        JSONData.hostingJson.Footer.FooterCol1.FooterPara.map((item,idx) => {
                                        return <FooterPara>
                                            {item.Para}
                                            </FooterPara>
                                        })
                                    }
                                    </FooterCol>
                                </Col>
                                <Col md="3">
                                
                                    <FooterCol>
                                        <FooterTitle>{JSONData.hostingJson.Footer.FooterCol2.FooterTitle}</FooterTitle>
                                        {
                                            JSONData.hostingJson.Footer.FooterCol2.FooterPara.map((item,idx) => {
                                            return <FooterPara>
                                                {item.Para}
                                                </FooterPara>
                                            })
                                        }
                                    </FooterCol>
                                </Col>
                                <Col md="3">
                                    <FooterCol>
                                        <FooterTitle>{JSONData.hostingJson.Footer.FooterCol3.FooterTitle}</FooterTitle>
                                            <ScrollSpy offset={-59} items={JSONData.hostingJson.Footer.Items} currentClassName="is-current">
                                            {
                                                JSONData.hostingJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                                return <FooterLi>
                                                    <AnchorLink offset={55} href={item.Href}>
                                                        {item.Menu}
                                                    </AnchorLink>
                                                </FooterLi>
                                                })
                                            }
                                            </ScrollSpy>
                                    </FooterCol>
                                </Col>
                                <Col md="3">
                                    <FooterCol>
                                        <FooterTitle>{JSONData.hostingJson.Footer.FooterCol4.FooterTitle}</FooterTitle>
                                        <FooterSocial href="#" aria-label="Facebook Link">
                                            <FbIcon/>
                                        </FooterSocial>
                                        <FooterSocial href="#" aria-label="Twitter Link">
                                            <TwitterIcon/>
                                        </FooterSocial>
                                        <FooterSocial href="#" aria-label="LinkedIn Link">
                                            <LinkedinIcon/>
                                        </FooterSocial>
                                        <FooterSocial href="#" aria-label="Instagram Link">
                                            <InstaIcon/>
                                        </FooterSocial>
                                    </FooterCol>
                                </Col>
                            </Row>
                        </Fade>
                    </Container>
                </FooterSection>
                <BottomFooterWrapper>
                    <Container>
                        <Fade delay={JSONData.hostingJson.Footer.DelayConstant}>
                            <Row>
                                <Col md="12">
                                    <BottomFooterPara>
                                        {JSONData.hostingJson.Footer.BottomFooterPara}
                                        <BottomLink rel="noreferrer" href={JSONData.hostingJson.Footer.BottomFooterLink} target="_blank">
                                        {JSONData.hostingJson.Footer.BottomFooterName}
                                        </BottomLink>
                                    </BottomFooterPara>
                                </Col>
                            </Row>
                        </Fade>
                    </Container>
                </BottomFooterWrapper>
            </FooterCustomContainerfluid>
        </div>
    );
}

export default Footer;