import React from 'react';
import {Container, Col} from '@bootstrap-styled/v4';
import {AboutSection,AboutLeft,
    AboutRight,AboutHeading,AboutDesc,AboutBtn,AboutRow} from './about.style'
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
            hostingJson{
                About{
                        DelayConstant
                        AboutLeftImage{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        AboutHeading
                        AboutDesc
                        AboutBtn
                    }

                }
            }
        `);
            
    return (
        <AboutSection id="aboutContainer">
            <Container>
                <AboutRow>
                    <Col md="6">
                        <Fade delay={1*JSONData.hostingJson.About.DelayConstant}>
                            <AboutLeft>
                                <GatsImg 
                                        fluid={JSONData.hostingJson.About.AboutLeftImage.childImageSharp.fluid} 
                                        className="AboutLeftImage"
                                        alt=""
                                    />
                            </AboutLeft>
                        </Fade>
                    </Col>
                    <Col md="6">
                        <AboutRight>
                            <Fade delay={1*JSONData.hostingJson.About.DelayConstant}>
                                <AboutHeading>{JSONData.hostingJson.About.AboutHeading}</AboutHeading>
                            </Fade>
                            <Fade delay={2*JSONData.hostingJson.About.DelayConstant}>
                                <AboutDesc>{JSONData.hostingJson.About.AboutDesc}</AboutDesc>
                            </Fade>
                            <Fade delay={3*JSONData.hostingJson.About.DelayConstant}>
                                <AboutBtn href="#">{JSONData.hostingJson.About.AboutBtn}</AboutBtn>
                            </Fade>
                        </AboutRight>
                    </Col>
                </AboutRow>
            </Container>
        </AboutSection>
    )
}

export default About;