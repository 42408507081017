import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {KeyFeaturesSection,
    KeyFeaturesCol,
    KeyFeaturesIcon,
    KeyFeaturesHeading,
    KeyFeaturesCustomContainer,
    KeyFeaturesDesc} from './keyfeatures.style'
import {SectionHeading} from '../Common/common.style';
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const KeyFeatures = () => {
    const JSONData  = useStaticQuery(graphql`
    query{
        hostingJson {
            KeyFeatures {
                DelayConstant
                SectionHeading
                SectionSubHeading
                KeyFeaturesCustomContainer{
                    KeyFeaturesIcon
                    KeyFeaturesHeading
                    KeyFeaturesDesc
                }
            }
        }
    }
`);
        return (
        <KeyFeaturesSection>
            <Container>
                <Row>
                    <Col md="12">
                        <Fade delay={JSONData.hostingJson.KeyFeatures.DelayConstant}>
                            <SectionHeading>{JSONData.hostingJson.KeyFeatures.SectionHeading}</SectionHeading>
                        </Fade>
                    </Col>
                </Row>
                <KeyFeaturesCustomContainer>
                    <Row>
                    {
                        JSONData.hostingJson.KeyFeatures.KeyFeaturesCustomContainer.map((item,idx) => {
                        return <Col md="6" sm="6" lg="4">
                            <Fade delay={(idx+1)*JSONData.hostingJson.KeyFeatures.DelayConstant}>
                                <KeyFeaturesCol>
                                    <KeyFeaturesIcon src={item.KeyFeaturesIcon} alt=""/>
                                    <KeyFeaturesHeading>{item.KeyFeaturesHeading}</KeyFeaturesHeading>
                                    <KeyFeaturesDesc>{item.KeyFeaturesDesc}</KeyFeaturesDesc>
                                </KeyFeaturesCol>
                            </Fade>
                        </Col>
                        })
                    }
                    </Row>
                </KeyFeaturesCustomContainer>
            </Container>
        </KeyFeaturesSection>
    );
}

export default KeyFeatures;
