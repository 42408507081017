import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Rubik', sans-serif;
        overflow:hidden;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Oxygen', sans-serif;
        font-weight: 700;
        color:#424345;
    }

    a{
        font-family: 'Rubik', sans-serif;
        font-size:16px;
    }
    form{
        margin-bottom:0px;
    }
`;

export default GlobalStyle;